window.addEventListener('load', function () {
  window.juraclimbs = new function () {
    var self = this;
    var cookieConsent = 'COOKIE HINWEIS:\nJuraclimbs verwendet ausschließlich "Technisch notwendige Cookies" die für grundlegende Funktionen der Website erforderlich sind.\n' +
      'Juraclimbs verwendet keine Drittanbieter-Cookies und führt keine Nutzerverfolgung mittels Cookies durch. \n' +
      'Detailiertere Infos zu Cookies findest du unter dem entsprechenden Link im Menü.\n' +
      'Mit dem Klick auf "OK" erlaubst du Juraclimbs einen Cookie zu setzten in dem vermerkt wird, dass dir dieser Hinweis in den nächsten 20 Tagen nicht mehr angezeigt werden soll.' +
      '\n\n' +
      'COOKIE ADVISE: \nJuraclimbs makes use of "technical cookies" which are necessary to provide its service.\n' +
      'Juraclimbs does not use any third-party  cookies and does not implement any user tracking by the use of cookies.\n' +
      'For further information about cookies visit the corresponding link in the menu.\n' +
      'By clicking on "OK" you allow Juraclimb to set a cookie with the information that this hint should not be displayed for the next 20 days.';

    this.showImage = function (image, title, description, photographer, username, closeText) {
      var cover = document.createElement('div');
      var txt = document.createElement('div');
      var close = document.createElement('div');
      var zoom = document.createElement('div');
      zoom.setAttribute('style', 'width:99%;')
      zoom.innerHTML = '<div style="position: absolute;right: 90px; width: 50px;"><a href="'+image+'" target="_blank"> <span class="svg_icon"><div class="zoom xxlarge"></div></span></a></div>';
      close.innerText = closeText;

      close.setAttribute('class', 'image-modal-close');
      cover.setAttribute('class', 'image-modal');
      txt.innerHTML = '<span style="padding:3px;font-weight: bold;"><b>' + title + '</b><br>' + description + '<br>Photo: ' + photographer + '<br>User: ' + username + '</div>';
      var i = document.createElement('img');
      i.setAttribute('src', image);
      cover.appendChild(i);
      cover.appendChild(txt);
      cover.appendChild(close);
      cover.appendChild(zoom);
      document.querySelector('#modal').appendChild(cover);
      cover.addEventListener('click', function () {
        cover.parentElement.removeChild(cover);
      })
    }

    this.setUsersCookieConsent = function () {
      // max age is 20 days
      document.cookie = "juraclimbs-visitor-accepted-cookie=true;max-age=1728000;"
    }

    this.checkCookieConsent = function () {
      return document.cookie.match(/juraclimbs-visitor-accepted-cookie/);
    };

    this.showConsentPopup = function () {
      if (!window.juraclimbs.checkCookieConsent()) {
        if (window.confirm(cookieConsent)) {
          window.juraclimbs.setUsersCookieConsent();
        }
      }
    }

    this.showConsentPopupDelayed = function () {
      window.setTimeout(self.showConsentPopup, 1000);
    };
  }
  window.juraclimbs.showConsentPopupDelayed();
  window.document.addEventListener('turbolinks:load', window.juraclimbs.showConsentPopupDelayed);
});

